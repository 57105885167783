import { Message } from "@/types/Message";
import { Talk } from "@/types/Talk";
import { handleWebMessage } from "../message";

const directFeTypes: any = {
  connect: "[新访客接入]",
  tags_add: "[添加标签]",
  tags_remove: "[移除标签]",

  wechat_worker_image: "[图片]",
  wechat_worker_video: "[视频]",
  wechat_worker_voice: "[语音]",
  wechat_worker_news: "[图文消息]",

  wechat_client_image: "[图片]",
  wechat_client_video: "[视频]",
  wechat_client_shortvideo: "[短视频]",
  wechat_client_link: "[链接]",
  wechat_client_location: "[地理位置]",
  wechat_client_voice: "[语音]",
  wechat_client_miniprogrampage: "[小程序链接]",
  wechat_client_file: "[文件]",

  weibo_client_image: "[图片]",
  weibo_client_video: "[视频]",
  weibo_client_voice: "[语音]",
  weibo_client_location: "[地理位置]",

  weibo_user_cmt: "[微博评论]",
  weibo_worker_cmt: "[微博评论]",

  douyin_worker_image: "[图片]",
  douyin_worker_video: "[视频]",
  douyin_worker_card: "[卡片消息]",

  douyin_client_image: "[图片]",
  douyin_client_h5: "[h5链接]",
  douyin_client_card: "[卡片消息]",
  douyin_client_cmt: "[视频评论]",
  douyin_client_user_video: "[视频]",
  douyin_worker_collect_phone: "[获取手机号]",
  douyin_worker_premium_card: "[高级预约]",

  client_message_fmc_image: "[图片]",
  worker_message_fmc_image: "[图片]",

  client_web_image_message: "[图片]",

  kuaishou_worker_image: "[图片]",
  kuaishou_client_image: "[图片]",

  redbook_worker_image: "[图片]",
  redbook_client_image: "[图片]",
  redbook_client_note: "[笔记]",
  redbook_client_loading_page: "[小红书落地页]",
  redbook_client_card: "[小红书服务卡片]",
  redbook_client_social_card: "[小红书名片]",
  redbook_client_trade_card: "[小红书交易卡]",
  redbook_worker_note: "[笔记]",
  redbook_worker_loading_page: "[小红书落地页]",
  redbook_worker_card: "[小红书服务卡片]",
  redbook_worker_social_card: "[小红书名片]",
  redbook_worker_trade_card: "[小红书交易卡]",

  client_message_web_audio: "[语音消息]",

  image: "[图片]",
  shake: "[用户发送了一条抖动]",
};

export function getLatestMsg(talk: Talk) {
  const con = talk.conversation.slice();
  let item: Message;

  while (con.length !== 0) {
    item = con.pop()!;

    if (directFeTypes[item.feType]) {
      return directFeTypes[item.feType];
    }

    if (item.feType === "notice") {
      if (item.type === "subscribe") {
        return "[关注]";
      }
      if (item.text && item.text.indexOf("点击菜单") !== -1) {
        return "[点击菜单]";
      }
      if (item.text) {
        return `[${item.text}]`;
      }
    }

    if (
      item.feType === "wechat_worker_text" ||
      item.feType === "wechat_client_text"
    ) {
      return item.text;
    }

    if (item.feType === "wechat_worker_linknews") {
      return "[图文链接]";
    }

    if (item.feType === "wechat_worker_miniprogrampage") {
      return "[小程序卡片]";
    }

    if (item.feType === "wechat_worker_video") {
      return "[视频素材]";
    }

    if (
      item.feType === "weibo_worker_text" ||
      item.feType === "weibo_client_text"
    ) {
      return item.text;
    }

    if (item.feType === "weibo_user_post") {
      switch (item.type) {
        case "at":
          return "[@我]";
        case "cmt":
          return "[微博评论]";
        case "like":
          return "[点赞]";
        case "link":
          return "[点链接]";
      }

      return "[微博消息]";
    }

    if (
      item.feType === "douyin_worker_text" ||
      item.feType === "douyin_client_text"
    ) {
      return item.text;
    }

    if (
      item.feType === "kuaishou_worker_text" ||
      item.feType === "kuaishou_client_text"
    ) {
      return item.text;
    }

    if (
      item.feType === "redbook_worker_text" ||
      item.feType === "redbook_client_text"
    ) {
      return item.text?.replace("<br>", "") || "";
    }

    if (item.feType === "redbook_client_note_cmt") {
      return item.content?.replace("<br>", "") || "";
    }

    if (item.feType === "redbook_worker_cmt") {
      return item.reply_content;
    }

    if (
      item.feType === "web_worker_message_text" ||
      item.feType === "web_worker_message_html" ||
      item.feType === "client_message" ||
      item.feType === "autoreply" ||
      item.feType === "web_client_text_message" ||
      item.feType === "web_client_html_message"
    ) {
      item = handleWebMessage(item);

      try {
        return item
          .displayText!.replace(/<img[^\>]+>/g, "[图片]")
          .replace(/<(?:.|\n)*?>/gm, "");
      } catch (error) {
        return item.displayText!.replace(/<(?:.|\n)*?>/gm, "");
      }
    }
  }

  return "";
}
