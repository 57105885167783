<template>
  <div
    class="msg-wrapper msg-worker msg-redbook preline"
    v-if="message.feType === 'redbook_worker_text'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker"
        >({{ message.worker.name }} {{ message.worker.nickname }})回复</span
      >
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <div v-if="message.recalled">（已撤回）</div>
        <div v-html="redbookFaceToHtml(message.text)"></div>
      </div>
      <div
        v-if="message.auto_typeText || message.is_recallable"
        class="bubble-type bubble-type-long"
      >
        {{ message.auto_typeText }}
        <span
          class="recall-text"
          v-if="message.is_recallable && showRecallBtn && !message.recalled"
          @click="recall"
        >
          撤回
        </span>
      </div>
    </div>
  </div>

  <div
    class="msg-wrapper msg-worker msg-temp msg-redbook preline"
    v-if="message.feType === 'temp_redbook_worker_text'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker"
        >({{ message.worker.name }} {{ message.worker.nickname }})回复</span
      >
    </div>
    <div class="msg-bubble">
      <div class="temp-status">
        <i
          class="iconfont sending"
          v-if="
            message.sendingStatus !== 'sent' &&
            message.sendingStatus !== 'error'
          "
          >&#xe609;</i
        >
        <i class="iconfont error" v-if="message.sendingStatus === 'error'"
          >&#xe606;</i
        >
      </div>
      <div v-html="redbookFaceToHtml(message.text)"></div>
      <div v-if="message.auto_typeText" class="bubble-type bubble-type-long">
        {{ message.auto_typeText }}
      </div>
    </div>
  </div>

  <div
    class="msg-wrapper msg-worker"
    v-if="message.feType === 'redbook_worker_image'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker"
        >({{ message.worker.name }} {{ message.worker.nickname }})回复</span
      >
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <div v-if="message.recalled">（已撤回）</div>
        <img :src="message.url" />
      </div>
      <div
        v-if="message.auto_typeText || message.is_recallable"
        class="bubble-type bubble-type-long"
      >
        {{ message.auto_typeText }}
        <span
          class="recall-text"
          v-if="message.is_recallable && showRecallBtn && !message.recalled"
          @click="recall"
        >
          撤回
        </span>
      </div>
    </div>
  </div>

  <div
    class="msg-wrapper msg-worker msg-redbook preline"
    v-if="message.feType === 'redbook_worker_note'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker"
        >({{ message.worker.name }} {{ message.worker.nickname }})回复</span
      >
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <div v-if="message.recalled">（已撤回）</div>
        <div class="note-unit">
          <div class="cover" v-if="message.cover_url">
            <img :src="message.cover_url" style="height: 80px" alt="" />
          </div>

          <div class="body" style="padding: 0 5px; white-space: normal">
            笔记标题：{{ message.title }}
            <a
              v-if="hostOS === 'WeChat'"
              style="color: #2ab3e6; text-decoration: none"
              @click="rediretUrl(message.share_url)"
              >查看笔记</a
            >
            <p style="color: gray">{{ message.publish_time }}</p>
          </div>
        </div>
      </div>
      <div
        v-if="message.auto_typeText || message.is_recallable"
        class="bubble-type bubble-type-long"
      >
        {{ message.auto_typeText }}
        <span
          class="recall-text"
          v-if="message.is_recallable && showRecallBtn && !message.recalled"
          @click="recall"
        >
          撤回
        </span>
      </div>
    </div>
  </div>

  <div
    class="msg-wrapper msg-worker msg-redbook preline"
    v-if="message.feType === 'redbook_worker_loading_page'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker"
        >({{ message.worker.name }} {{ message.worker.nickname }})回复</span
      >
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <div v-if="message.recalled">（已撤回）</div>
        <div class="card-unit">
          <div class="cover">
            <img referrerpolicy="no-referrer" :src="message.cover_url" alt="" />
          </div>

          <div class="body">
            <div class="title">{{ message.title }}</div>
            <div class="description">
              {{ message.page_desc || message.sub_title || message.desc }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="message.auto_typeText || message.is_recallable"
        class="bubble-type bubble-type-long"
      >
        {{ message.auto_typeText }}
        <span
          class="recall-text"
          v-if="message.is_recallable && showRecallBtn && !message.recalled"
          @click="recall"
        >
          撤回
        </span>
      </div>
    </div>
  </div>

  <div
    class="msg-wrapper msg-worker msg-redbook preline"
    v-if="message.feType === 'redbook_worker_card'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker"
        >({{ message.worker.name }} {{ message.worker.nickname }})回复</span
      >
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <div v-if="message.recalled">（已撤回）</div>
        <div class="card-unit">
          <div class="cover">
            <img referrerpolicy="no-referrer" :src="message.cover_url" alt="" />
          </div>

          <div class="body">
            <div class="title">{{ message.title }}</div>
            <div class="description">
              {{ message.page_desc || message.sub_title }}
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="message.auto_typeText || message.is_recallable"
        class="bubble-type bubble-type-long"
      >
        {{ message.auto_typeText }}
        <span
          class="recall-text"
          v-if="message.is_recallable && showRecallBtn && !message.recalled"
          @click="recall"
        >
          撤回
        </span>
      </div>
    </div>
  </div>

  <div
    class="msg-wrapper msg-worker msg-redbook preline"
    v-if="message.feType === 'redbook_worker_social_card'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker"
        >({{ message.worker.name }} {{ message.worker.nickname }})回复</span
      >
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <div v-if="message.recalled">（已撤回）</div>
        <div class="card-unit">
          <div class="cover">
            <img referrerpolicy="no-referrer" :src="message.image_url" alt="" />
          </div>

          <div class="body">
            <div
              class="title"
              style="
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              "
            >
              {{ message.name }}
            </div>
            <div class="description"  style="
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              ">{{ message.title }}</div>
          </div>
        </div>
      </div>

      <div
        v-if="message.auto_typeText || message.is_recallable"
        class="bubble-type bubble-type-long"
      >
        {{ message.auto_typeText }}
        <span
          class="recall-text"
          v-if="message.is_recallable && showRecallBtn && !message.recalled"
          @click="recall"
        >
          撤回
        </span>
      </div>
    </div>
  </div>

  <div
    class="msg-wrapper msg-worker msg-redbook preline"
    v-if="message.feType === 'redbook_worker_trade_card'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker"
        >({{ message.worker.name }} {{ message.worker.nickname }})回复</span
      >
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <div v-if="message.recalled">（已撤回）</div>
        <div class="card-unit">
          <div class="cover" >
            <img style="border-radius: 10%;" referrerpolicy="no-referrer" :src="message.image || message.image_url" alt="" />
          </div>

          <div class="body">
            <div
              class="title"
              style="
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              "
            >
              {{ message.title }}
            </div>
            <div class="description"  style="
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              ">{{ message.sub_title }}</div>
          </div>
        </div>
      </div>

      <div
        v-if="message.auto_typeText || message.is_recallable"
        class="bubble-type bubble-type-long"
      >
        {{ message.auto_typeText }}
        <span
          class="recall-text"
          v-if="message.is_recallable && showRecallBtn && !message.recalled"
          @click="recall"
        >
          撤回
        </span>
      </div>
    </div>
  </div>

  <div
    class="msg-wrapper msg-worker msg-redbook preline"
    v-if="message.feType === 'redbook_worker_cmt'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker"
        >({{ message.worker.name }} {{ message.worker.nickname }})回复</span
      >
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <div v-if="message.recalled">（已撤回）</div>
        {{ message.reply_content }}

        <div
          class="cmt-container"
          style="
            display: flex;
            align-items: start;
            gap: 10px;
            background-color: #f2f2f5;
            color: #000;
            padding: 5px 10px;
            border-radius: 5px;
          "
        >
          <img
            :src="message.cover_url"
            referrerpolicy="no-referrer"
            alt=""
            style="height: 80px"
          />
          <div>[评论]：{{ message.content }}</div>
        </div>
      </div>

      <div
        v-if="message.auto_typeText || message.is_recallable"
        class="bubble-type bubble-type-long"
      >
        {{ message.auto_typeText }}
        <span
          class="recall-text"
          v-if="message.is_recallable && showRecallBtn && !message.recalled"
          @click="recall"
        >
          撤回
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {
  MessageFeFrom,
  MessagePlatform,
  MessageCategory,
  WeiboMessageDivision,
} from "@/types/Message";

import { redbookFaceToHtml as redbookface } from "@/filters/redbookface";
import { isInRecallTimeMargin } from "@/utils/message-process/removeRecallMsg";
import { Toast, Dialog } from "vant";
import { recallMsg } from "@/api/common";
import { getHostType } from "@/utils/host";

export default defineComponent({
  props: {
    message: Object,
  },

  computed: {
    redbookFaceToHtml() {
      return (v) => {
        return redbookface(v);
      };
    },
  },

  components: {},

  data() {
    return {
      showRecallBtn: false,
      messageFeFroms: MessageFeFrom,
      messagePlatforms: MessagePlatform,
      messageCategories: MessageCategory,
      WeiboMessageDivision: WeiboMessageDivision,
      hostOS: "",
    };
  },

  beforeMount() {
    this.showRecallBtn =
      this.message.is_recallable &&
      isInRecallTimeMargin(this.message.microtime || 0) &&
      this.message.auto_typeText !== "关注追粉";
    this.hostOS = getHostType();
  },

  methods: {
    recall() {
      Dialog.confirm({
        message: "确定撤回消息吗？",
      }).then(
        () => {
          this.doRecall();
        },
        () => {}
      );
    },

    doRecall() {
      recallMsg({
        uid: this.message.uid,
        microtime: this.message.microtime,
      }).subscribe(
        () => {
          Toast.success("撤回成功");
          this.$emit("onRecall", {
            microtime: this.message.microtime,
          });
        },
        (err) => {
          Toast.fail(err.response.data.message);
        }
      );
    },

    rediretUrl(url) {
      window.open(url);
    },
  },
});
</script>

<style lang="less" scoped>
.note-unit {
  display: flex;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  align-items: center;
  width: 250px;
  border-radius: 5px;

  background: white;
  color: black;
  padding: 5px 10px;
}

.card-unit {
  display: flex;
  align-items: center;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  width: 250px;
  height: 60px;
  background: white;
  color: black;
  border-radius: 5px;

  padding: 5px 10px;

  .cover img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .body {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-align: left;

    .description {
      color: #999;
    }
  }
}
</style>
