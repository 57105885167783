<template>
  <div class="action-panel">
    <div class="action-block" @click="onChoosePhoto" v-if="currentVisitor?.platform !== 'weibo' && !toReplyCMT">
      <div class="icon-holder">
        <i class="iconfont">&#xe62b;</i>
      </div>
      <p>相册</p>
    </div>
    <div class="action-block" @click="showSnippetPicker = true">
      <div class="icon-holder">
        <i class="iconfont">&#xe603;</i>
      </div>
      <p>快捷话术</p>
    </div>
   
   
    <div class="action-block" @click="showEditTag = true">
      <div class="icon-holder">
        <i class="iconfont">&#xe765;</i>
      </div>
      <p>编辑标签</p>
    </div>

    <div class="action-block" @click="showNameCardPopup = true" v-if="currentVisitor?.platform === 'redbook'">
      <div class="icon-holder">
        <i class="iconfont">&#xe747;</i>
      </div>
      <p>名片</p>

    </div>

    <div class="action-block" @click="showTradeCardPopup = true" v-if="currentVisitor?.platform === 'redbook'">
      <div class="icon-holder">
        <i class="iconfont">&#xe662;</i>
      </div>
      <p>交易卡</p>

    </div>

    <div class="action-block" @click="close">
      <div class="icon-holder">
        <i class="iconfont">&#xe661;</i>
      </div>
      <p>关闭对话</p>
    </div>
    <div class="action-block" @click="block" v-if="!['weibo', 'kuaishou',].includes(currentVisitor?.platform)">
      <div class="icon-holder">
        <i class="iconfont">&#xe627;</i>
      </div>
      <p>拉黑</p>
    </div>
    <div class="action-block" @click="switchAI" v-if="currentVisitor.showAISwitch">
      <div class="icon-holder">
        <i class="iconfont">&#xe62c;</i>
      </div>
      <p>{{ currentVisitor.aiStatus ? '停止AI' : '开启AI' }}</p>
    </div>
    <div class="action-block" @click="report" v-if="isInspector">
      <div class="icon-holder">
        <i class="iconfont">&#xe60d;</i>
      </div>
      <p>举报</p>
    </div>
  
  </div>

  <tag-edit :uid="currentVisitor.uid" @close="showEditTag = false" v-if="showEditTag"></tag-edit>
  <name-card-list :appid="currentVisitor.appid"   @close="showNameCardPopup = false" v-if="showNameCardPopup"></name-card-list>
  <trade-card-list :appid="currentVisitor.appid"   @close="showTradeCardPopup = false" v-if="showTradeCardPopup"></trade-card-list>

  <van-popup v-model:show="showTransferPicker" position="bottom">
    <van-picker title="转接对话" :columns="workerList" @confirm="onTransfer" @cancel="showTransferPicker = false"
      :default-index="0" />
  </van-popup>

  <van-popup v-model:show="showSnippetPicker" position="bottom">
    <snippet-selector v-if="showSnippetPicker" @exit="showSnippetPicker = false" />
  </van-popup>

  <input type="file" id="image-upload-input" @change="uploadFile" accept="image/png, image/jpeg, image/gif"
    v-if="['wechat', 'web', 'kuaishou', 'redbook'].includes(currentVisitor?.platform)" />
  <input type="file" id="image-upload-input" @change="uploadFile" accept="image/png, image/jpeg, image/bmp, image/webp"
    v-if="currentVisitor?.platform === 'douyin'" />
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters, mapState } from "vuex";
import { Dialog, Toast, Popup, Picker } from "vant";
import { closeConversation, switchAiStatus } from "@/api/chat";
import TagEdit from "@/views/modals/TagEdit.vue";
import NameCardList from "@/views/NameCardList.vue";
import TradeCardList from "@/views/TradeCardList.vue";
import { getOnlineWorkers, transfer, sendImage } from "@/api/common";
import CommetFactory from "@/api/CommetFactory";
import { getFormatedMsg } from "@/utils/message";
import SnippetSelector from "@/components/SnippetSelector.vue";

export default defineComponent({
  components: {
    TagEdit,
    NameCardList,
    TradeCardList,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    SnippetSelector,
  },

  computed: {
    ...mapGetters({
      currentVisitor: "currentVisitor",
      toReplyCMT: "toReplyCMT",
    }),

    ...mapState({}),
  },

  data() {
    return {
      showEditTag: false,
      showNameCardPopup: false,
      showTradeCardPopup: false,
      workerList: [],
      showTransferPicker: false,
      showSnippetPicker: false,
      isInspector: false,
    };
  },

  beforeMount() {
    const device = localStorage.getItem("device");
    const deviceName = device.toLowerCase() || "";

    let isHuawei = ["huawei", "honor"].some((key) => {
      return deviceName.includes(key);
    })
    let companyId = localStorage.getItem("company_id");
    this.isInspector = companyId === 'c38cmvmmgccne';
  },

  methods: {
    close() {
      Dialog.confirm({
        message: "确定要关闭当前对话吗?",
      })
        .then(() => {
          closeConversation(this.currentVisitor.uid).subscribe(
            () => {
              Toast.success("已关闭对话");
              this.$router.replace("/chat-list");
            },
            () => {
              this.$router.replace("/chat-list");
            }
          );
        })
        .catch(() => { });
    },

    block() {
      this.$router.push(`/block`);
    },

    onChoosePhoto() {
      const el = document.getElementById("image-upload-input");
      if (el) {
        try {
          el.value = null;
        } catch (err) {
          el.parentNode.replaceChild(el.cloneNode(true), el);
        }
        el.click();
      }
    },

    report() {
      Toast.success("举报成功");
    },

    switchAI() {
      switchAiStatus({
        uid: this.currentVisitor.uid,
        status: !this.currentVisitor.aiStatus ? 1 : 0
      }).subscribe(() => {
        Toast.success("操作成功");
      })
    },

    uploadFile(e) {
      const toast = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "上传图片中",
      });

      sendImage(e.target.files[0], this.currentVisitor.platform).then(
        ([viewhost, filename]) => {
          let url = viewhost + "/" + filename;
          let content = {
            format: "image",
            url,
            type: "chatcdn",
            filename,
          };

          const Commet = CommetFactory(this.$store);

          const [msg] = getFormatedMsg(
            this.currentVisitor.platform,
            this.currentVisitor.uid,
            content
          );
          // this.$store.commit("UPDATE_TEMP_MESSAGE", tempMsg);

          Commet.send(msg);

          toast.clear();
        },
        () => {
          toast.clear();
        }
      );
    },
  },
});
</script>

<style lang="less" scoped>
.action-panel {
  //   position: absolute;
  //   bottom: 0;
  height: 170px;
  width: 100%;
  background-color: #f8f8f8;
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.04), 0 -2px 0 0 rgba(0, 0, 0, 0.01);
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow-y: auto;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

.icon-holder {
  background-color: white;
  border-radius: 5px;
  width: 48px;
  height: 48px;
  text-align: center;
  margin: 0 auto;
  border: 1px solid white;
  box-sizing: border-box;

  i.iconfont {
    line-height: 48px;
    font-size: 26px;
  }
}

.action-block {
  width: 25%;
  height: 80px;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  padding-bottom: 0;

  p {
    color: gray;
    margin: 0;
    margin-top: 2px;
    font-size: 12px;
    line-height: 1.4;
  }
}

#image-upload-input {
  display: none;
}
</style>
